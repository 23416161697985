<template>
    <zw-sidebar @shown="shown" :title="$t('common.title.customer_password')">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <validation-observer tag="div" ref="observer">
            <div v-if="!loading">
                <b-row>
                    <b-col sm="12">
                        <zw-input-group v-model="form.password"
                                        name="password"
                                        type="password"
                                        :validate="{ required: true }"
                        ></zw-input-group>
                    </b-col>
                </b-row>
                <div class="form-actions">
                    <b-row>
                        <b-col sm="12">
                            <b-button block @click="onSubmit" variant="info">
                                {{ $t('common.button.save') }}
                            </b-button>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {},
            form: {}
        }
    },
    methods: {
        shown() {
            this.form.id = this.payload.id
            this.loading = false
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    this.$store.dispatch('Customer/savePassword', JSON.parse(JSON.stringify(this.form)))
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>